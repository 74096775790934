import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import cn from 'classnames';
import styles from './_styles/InfoBar.scss';
import Chevron from '../../../components/icons/Chevron.js';
import Close from '../../../components/icons/Close.js';
import Button from '../../../components/atoms/Button.js';

interface InfoBarProps {
    title: string;
    children: React.ReactNode;
    defaultExpanded?: boolean;
}

const InfoBar: React.FC<InfoBarProps> = ({ title, children, defaultExpanded }) => {
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        if (defaultExpanded) {
            setExpanded(true);
        }
    }, [defaultExpanded]);

    return (
        <div className={cn([styles.infobar, expanded ? 'expanded' : ''])}>
            <Button
                kind="text"
                className={cn([styles.infobar_summary, expanded ? 'expanded' : ''])}
                onClick={() => {
                    setExpanded(!expanded);
                }}
            >
                {title}
                <Chevron className={styles.chevron_icon} />
            </Button>
            {!!expanded && (
                <div className={styles.infobar_details}>
                    <IconButton
                        className={styles.close_button}
                        onClick={() => {
                            setExpanded(!expanded);
                        }}
                    >
                        <Close className={styles.close_icon} />
                    </IconButton>
                    {children}
                </div>
            )}
        </div>
    );
};

export default InfoBar;
