import { Auth, API, Storage } from 'aws-amplify';
import { AuthOptions } from '@aws-amplify/auth/lib-esm/types/index.js';
import { clearLocalSessionStorage } from '../components/_helpers/clearLocalSessionStorage.js';

export const apiName = 'short-answer-api';
const url = new URL(window.location.href);

const configureAuth = () => {
    // ID's for the Short Answer Teacher user pool
    const adminPoolData: AuthOptions = {
        userPoolId: process.env.COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.COGNITO_APP_CLIENT_ID,
        identityPoolId: process.env.COGNITO_IDENTITY_POOL_ID,
        region: 'us-east-1',
        oauth: {
            domain: process.env.OAUTH_DOMAIN,
            scope: [],
            redirectSignIn: process.env.REDIRECT_SIGN_IN,
            redirectSignOut: process.env.REDIRECT_SIGN_OUT,
            responseType: 'token',
        },
    };

    Auth.configure(adminPoolData);
};

const configureAPI = () => {
    API.configure({
        endpoints: [
            {
                name: apiName,
                endpoint: `${url.protocol}//${url.host}/api`,
                custom_header: async () => {
                    try {
                        const session = await Auth.currentSession();
                        return {
                            Authorization: `Bearer ${session
                                .getAccessToken()
                                .getJwtToken()}`,
                        };
                    } catch (err) {
                        clearLocalSessionStorage?.();

                        // Force a redirect to sign-in
                        window.location.href = '/sign-in';

                        return {}; // return empty header to avoid throwing
                    }
                },
            },
        ],
    });
};

const configureStorage = () => {
    Storage.configure({
        AWSS3: {
            bucket: process.env.S3_BUCKET,
            region: 'us-east-1',
        },
    });
};

export const configureAmplify = () => {
    configureAuth();
    configureAPI();
    configureStorage();
};
