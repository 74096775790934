import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import IconButton from '@mui/material/IconButton';
import styles from './_styles/QuestionWritingScaffoldsList.scss';
import PlusFilled from '../../../components/icons/PlusFilled.js';
import { useGenerateWritingScaffold } from '../../../components/hooks/mutations/useGenerateWritingScaffold.js';
import { WritingScaffolds } from '../../../../types/WritingScaffolds.js';
import TextInput from '../../../components/atoms/TextInput.js';
import Close from '../../../components/icons/Close.js';
import { MagicWandLoader } from '../../../components/atoms/MagicWandLoader.js';
import { QuestionWritingScaffolds } from '../../../../types/Question.js';
import { AnalyticsEvents } from '../../../../types/AnalyticsEvents.js';
import { trackEvent } from '../../../lib/analytics.js';
import { convertScaffoldKeyToPlainText } from '../../../components/_helpers/convertScaffoldKeyToPlainText.js';

export interface QuestionWritingScaffoldsListProps {
    question: string;
    writingScaffolds: QuestionWritingScaffolds;
    onUpdateWritingScaffolds: React.Dispatch<
        React.SetStateAction<{
            outline?: string;
            'sentence-stems'?: string;
        }>
    >;
    isPremium: boolean;
    onClickUpgrade: () => void;
}

const writingScaffoldOptions: Array<{
    name: keyof WritingScaffolds;
    description: string;
}> = [
    {
        name: 'sentence-stems',
        description:
            'Provide sentence stems students can use to get started with their writing.',
    },
    {
        name: 'outline',
        description: 'Provide an outline students can use to organize their writing.',
    },
];

export const QuestionWritingScaffoldsList = ({
    question,
    writingScaffolds,
    onUpdateWritingScaffolds,
    isPremium,
    onClickUpgrade,
}: QuestionWritingScaffoldsListProps) => {
    /**
     * This is necessary because we need to show the loading state for the specific scaffold
     * If we just rely on the loading state of the mutation then it will look like both scaffolds
     * are loading content.
     */
    const [selectedScaffolds, setSelectedScaffolds] = useState<
        Array<{
            name: keyof WritingScaffolds;
            isLoading: boolean;
        }>
    >([]);
    const [localWritingScaffolds, setLocalWritingScaffolds] = useState(writingScaffolds);
    const [error, setError] = useState('');

    const generateWritingScaffold = useGenerateWritingScaffold();

    useEffect(() => {
        /**
         * This is where the scaffold content is updated in the parent component.
         */
        onUpdateWritingScaffolds(localWritingScaffolds);

        const scaffolds = Object.keys(localWritingScaffolds ?? {});

        scaffolds.forEach((scaffold) => {
            const selectedScaffold = selectedScaffolds.find((s) => s.name === scaffold);
            if (selectedScaffold) {
                setSelectedScaffolds((prev) => prev.filter((s) => s.name !== scaffold));
            }
        });
    }, [localWritingScaffolds]);

    const handleGenerateScaffold = async (scaffold: keyof WritingScaffolds) => {
        try {
            setError('');

            const scaffoldContent = await generateWritingScaffold.mutateAsync({
                question,
                scaffold,
            });

            setLocalWritingScaffolds((prev) => {
                return {
                    ...prev,
                    [scaffold]: scaffoldContent?.text,
                };
            });

            trackEvent(AnalyticsEvents.addWritingScaffoldsButtonClicked, {
                scaffold,
            });
        } catch (err) {
            const update = selectedScaffolds.map((s) => {
                if (s.name === scaffold) {
                    return {
                        ...s,
                        isLoading: false,
                    };
                }
                return s;
            });
            setSelectedScaffolds(update);
            setError(
                `Error generating ${convertScaffoldKeyToPlainText(
                    scaffold,
                )}. Please try again.`,
            );
        }
    };

    const handleDeleteScaffold = async (scaffold: keyof WritingScaffolds) => {
        trackEvent(AnalyticsEvents.removeWritingScaffoldsButtonClicked, {
            scaffold,
            content: localWritingScaffolds?.[scaffold],
        });

        const update = { ...localWritingScaffolds };
        delete update[scaffold];

        setLocalWritingScaffolds(update);
    };

    const handleScaffoldChange = (
        scaffold: keyof WritingScaffolds,
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setLocalWritingScaffolds({
            ...localWritingScaffolds,
            [scaffold]: e.target.value,
        });
    };

    return (
        <>
            <button
                type="button"
                aria-label="Upgrade to premium"
                onClick={() => {
                    trackEvent(AnalyticsEvents.writingScaffoldsUpgradeButtonClicked);
                    onClickUpgrade?.();
                }}
                className={cn([
                    styles.premium_overlay,
                    {
                        [styles.show_overlay]: !isPremium,
                    },
                ])}
            />
            <>
                <div className={styles.scaffolds_container}>
                    {writingScaffoldOptions.map((scaffold) => {
                        const hasGeneratedScaffold =
                            localWritingScaffolds?.[scaffold.name];
                        const selectedScaffold = selectedScaffolds.find(
                            (s) => s.name === scaffold.name,
                        );

                        return (
                            <button
                                type="button"
                                className={cn([
                                    styles.scaffold_container,
                                    {
                                        [styles.show_pointer]:
                                            !hasGeneratedScaffold &&
                                            isPremium &&
                                            !selectedScaffold?.isLoading,
                                    },
                                ])}
                                key={scaffold.name}
                                onClick={() => {
                                    if (!hasGeneratedScaffold && isPremium) {
                                        setSelectedScaffolds([
                                            ...selectedScaffolds,
                                            {
                                                name: scaffold.name,
                                                isLoading: true,
                                            },
                                        ]);
                                        handleGenerateScaffold(scaffold.name);
                                    }
                                }}
                            >
                                <div className={styles.scaffold_header_container}>
                                    <div className={styles.scaffold_header}>
                                        <h4 className={styles.scaffold_header}>
                                            {convertScaffoldKeyToPlainText(scaffold.name)}
                                        </h4>
                                        <p className={styles.scaffold_description}>
                                            {scaffold.description}
                                        </p>
                                    </div>

                                    <IconButton
                                        component="div"
                                        onClick={() =>
                                            handleDeleteScaffold(scaffold.name)
                                        }
                                        disabled={selectedScaffold?.isLoading}
                                    >
                                        {hasGeneratedScaffold ||
                                        selectedScaffold?.isLoading ? (
                                            <Close className={styles.close_icon} />
                                        ) : (
                                            <PlusFilled className={styles.plus_icon} />
                                        )}
                                    </IconButton>
                                </div>

                                {selectedScaffold?.isLoading && (
                                    <div
                                        id={scaffold.name}
                                        className={styles.magic_wand_container}
                                    >
                                        <MagicWandLoader
                                            parentElement={`#${scaffold.name}`}
                                        />
                                    </div>
                                )}

                                {hasGeneratedScaffold && (
                                    <TextInput
                                        className={styles.scaffold_input}
                                        defaultValue={hasGeneratedScaffold}
                                        multiline
                                        onChange={(e) =>
                                            handleScaffoldChange(scaffold.name, e)
                                        }
                                    />
                                )}
                            </button>
                        );
                    })}
                </div>
                {error && <p className={styles.error_message}>{error}</p>}
            </>
        </>
    );
};

export default QuestionWritingScaffoldsList;
