import React from 'react';
import { init, reactRouterV6BrowserTracingIntegration } from '@sentry/react';
import {
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from 'react-router-dom';

export const initSentry = () => {
    const url = window.location.href;

    if (!url.includes('localhost')) {
        const environment = !url.includes('app') ? 'development' : 'production';

        init({
            dsn: process.env.SENTRY_DSN,
            environment,
            tracesSampleRate: 1.0,
            release: `2.1.2-${environment}`,
            integrations: [
                reactRouterV6BrowserTracingIntegration({
                    useEffect: React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                }),
            ],
        });
    }
};
