import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import { GSDevTools } from 'gsap/GSDevTools';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin';
import { SplitText } from 'gsap/SplitText';
import { CustomEase } from 'gsap/CustomEase';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { QueryClient, QueryClientProvider } from 'react-query';
import router from './router/index.js';
import { SocketProvider } from './components/context/Socket.js';
import { configureAmplify } from './lib/amplify.js';
import { initSentry } from './lib/sentry.js';
import { ErrorBoundaryFallback } from './ErrorBoundaryFallback.js';
import './styles/normalize.scss';
import { MaintenanceMode } from './components/pages/MaintenanceMode.js';

const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient();

const MAINTENANCE_MODE = process.env.MAINTENANCE_MODE === 'true';

// Override the default font family for MUI components
const theme = createTheme({
    typography: {
        fontFamily: 'Satoshi, sans-serif',
    },
});

const App = () => {
    if (MAINTENANCE_MODE) {
        return <MaintenanceMode />;
    }

    return (
        <React.StrictMode>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
                        <QueryClientProvider client={queryClient}>
                            <SocketProvider>
                                <RouterProvider router={router} />
                            </SocketProvider>
                        </QueryClientProvider>
                    </ErrorBoundary>
                </ThemeProvider>
            </StyledEngineProvider>
        </React.StrictMode>
    );
};

root.render(<App />);

configureAmplify();
initSentry();

gsap.config({ nullTargetWarn: false });
gsap.registerPlugin(
    MotionPathPlugin,
    useGSAP,
    MorphSVGPlugin,
    SplitText,
    CustomEase,
    DrawSVGPlugin,
    GSDevTools,
);
