import React, { useContext, useState } from 'react';
import { captureException } from '@sentry/react';
import Link from '@mui/material/Link';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import styles from '../_styles/QuestionWizardSteps/SuccessCriteria.scss';
import { RoomContext } from '../../../../components/context/Room.js';
import {
    useQuestionWizardContext,
    useDispatchQuestionWizardContext,
} from '../../context/QuestionWizard.js';
import { useCreateStripeCheckoutSession } from '../../../../components/hooks/mutations/useCreateStripeCheckoutSession.js';
import { useUpdateRoom } from '../../../../components/hooks/mutations/useUpdateRoom.js';
import { trackEvent } from '../../../../lib/analytics.js';
import { FEEDBACK } from '../../../../../constants/feedback.js';
import { hasPremiumSubscription } from '../../../../../shared/subscriptionStatusHelpers.js';
import { AnalyticsEvents } from '../../../../../types/AnalyticsEvents.js';
import { FeedbackValue } from '../../../../../types/Feedback.js';
import { UserEditableRoom } from '../../../../../types/Room.js';
import QuestionWizardStepLayout from '../QuestionWizardStepLayout.js';
import Brain from '../../../../components/icons/Brain.js';
import SuccessCriteriaCard from '../SuccessCriteriaCard.js';
import CreateEditSuccessCriteriaModal from '../CreateEditSuccessCriteriaModal.js';
import Close from '../../../../components/icons/Close.js';
import UpgradePremiumModal from '../../../../components/molecules/UpgradePremiumModal.js';

enum CriteriaTypes {
    content = 'content',
    structure = 'structure',
    createOwn = 'createOwn',
}

type CriteriaValuesType = {
    [key in CriteriaTypes]: {
        label: string;
        tabValue: CriteriaTypes;
        values: FeedbackValue[];
        isEditable: boolean;
    };
};

const SuccessCriteria = () => {
    const room = useContext(RoomContext);
    const updateRoom = useUpdateRoom();
    const questionWizardState = useQuestionWizardContext();
    const questionWizardDispatch = useDispatchQuestionWizardContext();
    const createStripeCheckoutSession = useCreateStripeCheckoutSession();

    const [selectedTab, setSelectedTab] = useState<string>('content');
    const [customCriteria, setCustomCriteria] = useState<FeedbackValue[]>(
        room.feedback_opts?.values ?? [],
    );
    const [showCreateEditSuccessCriteriaModal, setShowCreateEditSuccessCriteriaModal] =
        useState(false);
    const [showUpgradePremiumModal, setShowUpgradePremiumModal] = useState(false);
    const [editableFeedbackCriteria, setEditableFeedbackCriteria] =
        useState<FeedbackValue>(null);

    const isPremium = hasPremiumSubscription(room);
    const selectedCriteriaLength =
        questionWizardState.question.feedback_opts?.values?.length;

    const criteriaValues: CriteriaValuesType = {
        content: {
            label: 'Content',
            tabValue: CriteriaTypes.content,
            values: FEEDBACK.content,
            isEditable: false,
        },
        structure: {
            label: 'Structure',
            tabValue: CriteriaTypes.structure,
            values: FEEDBACK.structure,
            isEditable: false,
        },
        createOwn: {
            label: 'Create Your Custom',
            tabValue: CriteriaTypes.createOwn,
            values: customCriteria,
            isEditable: true,
        },
    };

    const updateRoomFeedbackOpts = async (newFeedbackOpts: FeedbackValue[]) => {
        const newRoomData: UserEditableRoom = {
            feedback_opts: { values: newFeedbackOpts },
        };
        updateRoom.mutate(
            {
                room: newRoomData,
            },
            {
                onSuccess: (result) => {
                    setCustomCriteria(result.room.feedback_opts.values);
                },
                onError: (err) => {
                    captureException(err);
                },
            },
        );
    };

    const handleAddEditCustomCriteria = ({ id, feedback, description }) => {
        let newCustomCriteria = criteriaValues.createOwn.values;
        if (id) {
            newCustomCriteria = criteriaValues.createOwn.values.map((c) =>
                c.id === id ? { ...c, feedback, description } : c,
            );
        } else {
            const newId =
                10 + // initial 10 (new feedback constants)
                criteriaValues.content.values.length +
                criteriaValues.structure.values.length +
                criteriaValues.createOwn.values.length +
                1; // new id
            newCustomCriteria.push({ id: newId.toString(), feedback, description });
        }
        updateRoomFeedbackOpts(newCustomCriteria);
    };

    const handleDeleteCustomCriteria = (deleteId: string) => {
        const newCustomCriteria = criteriaValues.createOwn.values.filter(
            (c) => c.id !== deleteId,
        );
        updateRoomFeedbackOpts(newCustomCriteria);
    };

    const handleAddFeedbackOpts = (feedbackValue) => {
        const feedbackValues = questionWizardState.question.feedback_opts.values;
        const newFeedbackOpts = {
            values:
                (!isPremium && feedbackValues.length >= 3) ||
                (isPremium && feedbackValues.length >= 5)
                    ? feedbackValues
                    : [...feedbackValues, feedbackValue],
        };
        questionWizardDispatch({
            type: 'setQuestion',
            question: { feedback_opts: newFeedbackOpts },
        });
    };

    const handleRemoveFeedbackOpts = (feedbackValue) => {
        const newFeedbackOpts = {
            values: questionWizardState.question.feedback_opts.values.filter(
                (c) => c.id !== feedbackValue.id,
            ),
        };
        questionWizardDispatch({
            type: 'setQuestion',
            question: { feedback_opts: newFeedbackOpts },
        });
    };

    const handleClickUpgradeButton = () => {
        createStripeCheckoutSession.mutate({
            planType: 'annual',
        });
    };

    const isCriteriaDisabled = (feedbackValue: FeedbackValue) => {
        const isCriteriaAdded = questionWizardState.question.feedback_opts.values.find(
            (c) => c.id === feedbackValue.id,
        );
        return !!isCriteriaAdded;
    };

    return (
        <QuestionWizardStepLayout
            title={
                <>
                    Pick <strong>1-5 success criteria</strong> for your students to use
                    when evaluating each other’s responses.
                </>
            }
            infoBarTitle="What makes for good success criteria?"
            infoBarContents={
                <>
                    <h2>Great Success Criteria...</h2>
                    <ul>
                        <li>Are aligned with the learning outcomes of the lesson plan</li>
                        <li>Describe positive aspects of an ideal response</li>
                        <li>
                            Are limited. To manage cognitive load, we recommend 1 to 5
                            maximum
                        </li>
                    </ul>
                    <div className={styles.learn_more_container}>
                        <Brain className={styles.brain_icon} />
                        <div>
                            <h4>Wanna learn more?</h4>
                            <p>
                                Read more about how we use research in{' '}
                                <Link
                                    href="https://myshortanswer.com/whats-with-all-the-comparing-in-short-answer/ "
                                    className={styles.resources_link}
                                    target="_blank"
                                    rel="noreferrer"
                                    underline="hover"
                                    onClick={() =>
                                        trackEvent(AnalyticsEvents.resourcesLinkClicked)
                                    }
                                >
                                    comparative judgment
                                </Link>{' '}
                                to improve student learning outcomes.
                            </p>
                        </div>
                    </div>
                </>
            }
        >
            <>
                <Tabs
                    value={selectedTab}
                    onChange={(_, update) => setSelectedTab(update)}
                    className={styles.tabs}
                >
                    {Object.values(criteriaValues).map((c) => (
                        <Tab
                            key={c.tabValue}
                            label={c.label}
                            value={c.tabValue}
                            className={styles.tab}
                        />
                    ))}
                </Tabs>
                <div className={styles.criteria_list}>
                    <div className={styles.criteria_list_container}>
                        {selectedTab === CriteriaTypes.createOwn && (
                            <>
                                <SuccessCriteriaCard
                                    feedbackOpt={{ id: '', feedback: 'Create a new one' }}
                                    primaryLabel="Create"
                                    primaryOnClick={() => {
                                        setEditableFeedbackCriteria(null);
                                        setShowCreateEditSuccessCriteriaModal(true);
                                    }}
                                    variant="action"
                                />
                                {/* Empty grid cell needed for expected layout */}
                                <div />
                            </>
                        )}
                        {criteriaValues[selectedTab].values.map((c) => (
                            <SuccessCriteriaCard
                                key={c.id}
                                feedbackOpt={c}
                                primaryLabel={isCriteriaDisabled(c) ? 'Added!' : 'Add'}
                                primaryOnClick={
                                    !isPremium && selectedCriteriaLength >= 3
                                        ? () => setShowUpgradePremiumModal(true)
                                        : handleAddFeedbackOpts
                                }
                                secondaryLabel={
                                    criteriaValues[selectedTab].isEditable && 'Edit'
                                }
                                secondaryOnClick={() => {
                                    setEditableFeedbackCriteria(c);
                                    setShowCreateEditSuccessCriteriaModal(true);
                                }}
                                variant={
                                    (isPremium && selectedCriteriaLength >= 5) ||
                                    (!isPremium && selectedCriteriaLength >= 3)
                                        ? 'outlinedDisabled'
                                        : 'outlined'
                                }
                                disabled={isCriteriaDisabled(c)}
                            />
                        ))}
                    </div>
                </div>
                <div className={styles.slected_criteria_container}>
                    <div className={styles.header_container}>
                        <h5>
                            Selected Criteria for this Question (
                            {selectedCriteriaLength || 0})
                        </h5>
                        {!isPremium && (
                            <Alert
                                severity="warning"
                                className={styles.max_criteria_alert}
                            >
                                {`${
                                    Math.min(selectedCriteriaLength, 3) ?? 0
                                }/3 feedback criteria used. `}
                                <button
                                    type="button"
                                    className={styles.button_link}
                                    onClick={() => {
                                        trackEvent(
                                            AnalyticsEvents.feedbackCriteriaUpdgradeButtonClicked,
                                        );
                                        handleClickUpgradeButton();
                                    }}
                                >
                                    Upgrade
                                </button>{' '}
                                for unlimited.
                            </Alert>
                        )}
                    </div>
                    <div className={styles.feedback_list}>
                        {questionWizardState.question.feedback_opts?.values?.map((c) => (
                            <div key={c.id} className={styles.feedback_opt}>
                                <p>{c.feedback}</p>
                                <IconButton onClick={() => handleRemoveFeedbackOpts(c)}>
                                    <Close className={styles.close_icon} />
                                </IconButton>
                            </div>
                        ))}
                    </div>
                </div>
                <CreateEditSuccessCriteriaModal
                    isOpen={showCreateEditSuccessCriteriaModal}
                    feedbackOpt={editableFeedbackCriteria}
                    setFeedbackCriteria={setEditableFeedbackCriteria}
                    onClose={() => setShowCreateEditSuccessCriteriaModal(false)}
                    onSave={handleAddEditCustomCriteria}
                    onDelete={handleDeleteCustomCriteria}
                />
                <UpgradePremiumModal
                    isOpen={showUpgradePremiumModal}
                    onClose={() => setShowUpgradePremiumModal(false)}
                    onSubmit={handleClickUpgradeButton}
                />
            </>
        </QuestionWizardStepLayout>
    );
};

export default SuccessCriteria;
