import React from 'react';
import cn from 'classnames';
import styles from './_styles/CreateEditSuccessCriteriaModal.scss';
import TextInput from '../../../components/atoms/TextInput.js';
import Button from '../../../components/atoms/Button.js';
import { Dialog } from '../../../components/atoms/Dialog.js';
import { FeedbackValue } from '../../../../types/Feedback.js';
import Trash from '../../../components/icons/Trash.js';

interface CreateEditSuccessCriteriaModalProps {
    isOpen: boolean;
    feedbackOpt: FeedbackValue;
    setFeedbackCriteria: (feedbackValue: FeedbackValue) => void;
    onClose: () => void;
    onSave: (feedbackValue: FeedbackValue) => void;
    onDelete: (deleteId: string) => void;
}

const CreateEditSuccessCriteriaModal: React.FC<CreateEditSuccessCriteriaModalProps> = ({
    isOpen,
    feedbackOpt,
    setFeedbackCriteria,
    onClose,
    onSave,
    onDelete,
}) => {
    const editModeText = feedbackOpt?.id ? 'Edit your' : 'Create new';

    const handleSubmit = () => {
        onSave({
            id: feedbackOpt?.id,
            feedback: feedbackOpt?.feedback,
            description: feedbackOpt?.description,
        });
        onClose();
    };

    const descriptionCharCount =
        feedbackOpt?.description?.length > 200 ? 200 : feedbackOpt?.description?.length;

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <div className={styles.modal_contents}>
                <h2>{editModeText} success criteria</h2>

                <TextInput
                    value={feedbackOpt?.feedback}
                    required
                    onChange={(e) =>
                        setFeedbackCriteria({
                            ...feedbackOpt,
                            feedback: e.target.value,
                        })
                    }
                    label="Criteria Name"
                    className={styles.input}
                    variant="outlined"
                />
                <span className={styles.input_description_container}>
                    <TextInput
                        value={feedbackOpt?.description}
                        required
                        multiline
                        onChange={(e) =>
                            setFeedbackCriteria({
                                ...feedbackOpt,
                                description: e.target.value,
                            })
                        }
                        inputProps={{
                            maxLength: 230,
                        }}
                        label="Criteria Description"
                        className={cn([styles.input, styles.input_description])}
                        variant="outlined"
                    />
                    <div className={styles.char_count}>{`${
                        descriptionCharCount ?? 0
                    }/230`}</div>
                </span>

                <div className={styles.button_container}>
                    {feedbackOpt?.id ? (
                        <Button
                            kind="danger"
                            onClick={() => {
                                onDelete(feedbackOpt?.id);
                                onClose();
                            }}
                            startIcon={<Trash />}
                        >
                            Delete
                        </Button>
                    ) : (
                        <Button kind="secondary" onClick={onClose}>
                            Cancel
                        </Button>
                    )}

                    <Button
                        disabled={
                            feedbackOpt?.feedback === '' ||
                            feedbackOpt?.description === ''
                        }
                        type="submit"
                        kind="primary"
                        onClick={handleSubmit}
                    >
                        {editModeText} criteria
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default CreateEditSuccessCriteriaModal;
