import React, { useRef } from 'react';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import styles from './_styles/QuestionWizardStepLayout.scss';
import {
    useQuestionWizardContext,
    useDispatchQuestionWizardContext,
    getPrevStep,
    getNextStep,
} from '../context/QuestionWizard.js';
import InfoBar from './InfoBar.js';
import Chevron from '../../../components/icons/Chevron.js';
import Button from '../../../components/atoms/Button.js';

interface QuestionWizardStepLayoutProps {
    title: React.ReactNode;
    subtitle?: string;
    children: React.ReactNode;
    infoBarTitle?: string;
    infoBarContents?: React.ReactNode;
    primaryCTALabel?: string;
    onSubmit?: () => void;
    defaultExpanded?: boolean;
}

const QuestionWizardStepLayout: React.FC<QuestionWizardStepLayoutProps> = ({
    title,
    subtitle,
    children,
    infoBarTitle,
    infoBarContents,
    primaryCTALabel = 'Next',
    onSubmit,
    defaultExpanded,
}) => {
    const stepRef = useRef();
    const questionWizardState = useQuestionWizardContext();
    const questionWizardDispatch = useDispatchQuestionWizardContext();

    const prevStep = getPrevStep(questionWizardState.step);
    const nextStep = getNextStep(questionWizardState.step);

    useGSAP(
        () => {
            gsap.from('h1, h3', {
                delay: 0.1,
                duration: 0.5,
                ease: 'power2.out',
                y: 100,
            });
            gsap.from('#inner_container', {
                delay: 0.2,
                duration: 1,
                ease: 'power1.out',
                opacity: 0.005,
            });
        },
        { scope: stepRef },
    );

    return (
        <div className={styles.step_wrapper} ref={stepRef}>
            <div
                style={{
                    overflow: 'hidden',
                }}
            >
                {!!subtitle && <h3>{subtitle}</h3>}
                <h1>{title}</h1>
            </div>

            <div className={styles.container_wrapper}>
                <div className={styles.left_container}>
                    <div id="inner_container" className={styles.inner_container}>
                        {children}
                    </div>

                    {!!prevStep && (
                        <Button
                            kind="secondary"
                            disabled={questionWizardState.disabledWizardSteps[prevStep]}
                            onClick={() =>
                                questionWizardDispatch({
                                    type: 'prevStep',
                                })
                            }
                            className={styles.back_button}
                        >
                            <Chevron className={styles.left_chevron} /> Back
                        </Button>
                    )}

                    {(!!nextStep || !!onSubmit) && (
                        <Button
                            kind="primary"
                            disabled={questionWizardState.disabledWizardSteps[nextStep]}
                            onClick={() => {
                                if (nextStep && !onSubmit) {
                                    questionWizardDispatch({
                                        type: 'nextStep',
                                    });
                                }
                                onSubmit?.();
                            }}
                            className={styles.next_button}
                        >
                            {primaryCTALabel}{' '}
                            {!!nextStep && <Chevron className={styles.right_chevron} />}
                        </Button>
                    )}
                </div>
                {!!infoBarTitle && !!infoBarContents && (
                    <div className={styles.right_container}>
                        <InfoBar title={infoBarTitle} defaultExpanded={defaultExpanded}>
                            {infoBarContents}
                        </InfoBar>
                    </div>
                )}
            </div>
        </div>
    );
};

export default QuestionWizardStepLayout;
