import React from 'react';
import styles from './_styles/SuccessCriteriaCard.scss';
import Button from '../../../components/atoms/Button.js';
import { FeedbackValue } from '../../../../types/Feedback.js';

interface SuccessCriteriaCardProps {
    feedbackOpt: FeedbackValue;
    primaryLabel?: string;
    primaryOnClick?: (feedbackValue: FeedbackValue) => void;
    secondaryLabel?: string;
    secondaryOnClick?: () => void;
    variant?: 'action' | 'outlined' | 'outlinedDisabled';
    disabled?: boolean;
}

const SuccessCriteriaCard: React.FC<SuccessCriteriaCardProps> = ({
    feedbackOpt,
    primaryLabel,
    primaryOnClick,
    secondaryLabel,
    secondaryOnClick,
    variant = 'outlined',
    disabled,
}) => {
    return (
        <div className={styles.card_container}>
            <div className={styles.content_container}>
                <h4>{feedbackOpt.feedback}</h4>
                {feedbackOpt.description && <p>{feedbackOpt.description}</p>}
            </div>
            <div className={styles.button_container}>
                {secondaryLabel && secondaryOnClick && !disabled && (
                    <Button
                        kind="text"
                        onClick={secondaryOnClick}
                        className={styles.text}
                    >
                        {secondaryLabel}
                    </Button>
                )}
                {primaryLabel && primaryOnClick && (
                    <Button
                        kind={variant === 'action' ? 'secondary' : 'text'}
                        onClick={() => primaryOnClick(feedbackOpt)}
                        disabled={disabled}
                        className={
                            variant === 'outlinedDisabled' && styles.outline_disabled
                        }
                    >
                        {primaryLabel}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default SuccessCriteriaCard;
