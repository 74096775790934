import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import styles from './_styles/QuestionsWizardTabs.scss';
import {
    WizardSteps,
    useQuestionWizardContext,
    useDispatchQuestionWizardContext,
    stepsArray,
} from '../context/QuestionWizard.js';

const wizardSteps: WizardSteps<{ label: string; displayNavigation: boolean }> = {
    question: {
        label: 'Question or prompt',
        displayNavigation: true,
    },
    files: {
        label: 'Files',
        displayNavigation: true,
    },
    scaffolds: {
        label: 'Scaffolds',
        displayNavigation: true,
    },
    successCriteria: {
        label: 'Success criteria',
        displayNavigation: true,
    },
    review: {
        label: 'Review',
        displayNavigation: true,
    },
};
const QuestionWizardTabs = () => {
    const questionWizardState = useQuestionWizardContext();
    const questionWizardDispatch = useDispatchQuestionWizardContext();

    return (
        <Tabs
            value={questionWizardState.step}
            onChange={(_, update) =>
                questionWizardDispatch({
                    type: 'setStep',
                    step: update,
                })
            }
            className={styles.tabs}
            orientation="vertical"
        >
            {stepsArray.length > 0 &&
                stepsArray.map(
                    (wizardStep, i) =>
                        !!wizardSteps[wizardStep].displayNavigation && (
                            <Tab
                                key={stepsArray[i]}
                                label={wizardSteps[wizardStep].label}
                                value={stepsArray[i]}
                                className={styles.tab}
                                disabled={
                                    questionWizardState.disabledWizardSteps[wizardStep]
                                }
                            />
                        ),
                )}
        </Tabs>
    );
};

export default QuestionWizardTabs;
