import React, { useState } from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import styles from '../_styles/QuestionWizardSteps/QuestionPrompt.scss';
import {
    useQuestionWizardContext,
    useDispatchQuestionWizardContext,
} from '../../context/QuestionWizard.js';
import QuestionWizardStepLayout from '../QuestionWizardStepLayout.js';
import { FileDropzoneInput } from '../../../../components/atoms/FileDropzoneInput.js';

const Files = () => {
    const questionWizardState = useQuestionWizardContext();
    const questionWizardDispatch = useDispatchQuestionWizardContext();

    const [dropFilesInputError, setDropFilesInputError] = useState('');

    return (
        <QuestionWizardStepLayout
            title={
                <>
                    Do you want to attach <strong>any files</strong> with your question or
                    prompt?
                </>
            }
            subtitle="(Optional)"
            infoBarTitle="How might I use files?"
            infoBarContents={
                <>
                    <h2>Attaching files with a question helps...</h2>
                    <ul>
                        <li>
                            When students need to reference specific pictures, charts, or
                            documents in their response
                        </li>
                        <li>
                            If you want to include worked examples for students to
                            reference while responding
                        </li>
                        <li>
                            If you want to provide hints or “cheat sheets” for students to
                            reference
                        </li>
                    </ul>
                </>
            }
        >
            <div>
                <FileDropzoneInput
                    fileList={questionWizardState.files}
                    onDrop={(files: FileList) =>
                        questionWizardDispatch({
                            type: 'setFiles',
                            files,
                        })
                    }
                    onError={setDropFilesInputError}
                />
            </div>

            {dropFilesInputError && (
                <FormHelperText className={styles.error} error>
                    {dropFilesInputError}
                </FormHelperText>
            )}
        </QuestionWizardStepLayout>
    );
};

export default Files;
