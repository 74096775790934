import React, { useContext } from 'react';
import {
    useQuestionWizardContext,
    useDispatchQuestionWizardContext,
} from '../../context/QuestionWizard.js';
import QuestionWizardStepLayout from '../QuestionWizardStepLayout.js';
import { QuestionWritingScaffoldsList } from '../QuestionWritingScaffoldsList.js';
import { useCreateStripeCheckoutSession } from '../../../../components/hooks/mutations/useCreateStripeCheckoutSession.js';
import { hasPremiumSubscription } from '../../../../../shared/subscriptionStatusHelpers.js';
import { RoomContext } from '../../../../components/context/Room.js';
import { QuestionWritingScaffolds } from '../../../../../types/Question.js';

const Scaffolds = () => {
    const room = useContext(RoomContext);
    const questionWizardState = useQuestionWizardContext();
    const questionWizardDispatch = useDispatchQuestionWizardContext();

    const isPremium = hasPremiumSubscription(room);

    const createStripeCheckoutSession = useCreateStripeCheckoutSession();

    const handleClickUpgradeButton = () => {
        createStripeCheckoutSession.mutate({
            planType: 'annual',
        });
    };

    const handleUpdateWritingScaffolds = (scaffolds: QuestionWritingScaffolds) => {
        questionWizardDispatch({
            type: 'setQuestion',
            question: { writing_scaffolds: scaffolds },
        });
    };

    return (
        <QuestionWizardStepLayout
            title={
                <>
                    Add <strong>writing scaffolds?</strong>
                </>
            }
            subtitle="(Optional)"
            infoBarTitle="What are writing scaffolds?"
            infoBarContents={
                <>
                    <h2>Writing scaffolds...</h2>
                    <ul>
                        <li>
                            Decrease the cognitive load of a writing task for emerging
                            writers
                        </li>
                        <li>
                            Are helpful if you have students who often get stuck when
                            their getting started with writing
                        </li>
                        <li>Will be available to all students when added here</li>
                        <li>
                            Can be viewed after activities so you can see which students
                            used which scaffolds
                        </li>
                    </ul>
                </>
            }
        >
            <div
                style={{
                    position: 'relative',
                }}
            >
                <QuestionWritingScaffoldsList
                    question={questionWizardState.question.question}
                    writingScaffolds={questionWizardState.question.writing_scaffolds}
                    onUpdateWritingScaffolds={handleUpdateWritingScaffolds}
                    isPremium={isPremium}
                    onClickUpgrade={handleClickUpgradeButton}
                />
            </div>
        </QuestionWizardStepLayout>
    );
};

export default Scaffolds;
