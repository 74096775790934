import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { Question } from '../../../../types/Question.js';
import { useSingleQuestion } from '../../../components/hooks/queries/useSingleQuestion.js';
import { useQuestionFileAttachments } from '../../../components/hooks/queries/useQuestionFileAttachments.js';

/**
 * STEPS enum is the Single Source of Truth for steps names and order.
 * stepsArray is generated after to handle navigation and display of the wizard navigation tabs.
 * Update enum to change step order o include new steps.
 */
enum STEPS {
    question = 'question',
    files = 'files',
    scaffolds = 'scaffolds',
    successCriteria = 'successCriteria',
    review = 'review',
}

const stepsArray = Object.values(STEPS);

/**
 * Typescript types to strict the objects created based on the steps, state values and state actions.
 * WizardSteps is used in the disabledWizardSteps initial values, also in the navigation tabs options
 * and the steps components.
 * All of them are based on the enum STEPS.
 */
type WizardSteps<T> = {
    [key in STEPS]: T;
};

type QuestionWizardContextState = {
    step: STEPS | null;
    disabledWizardSteps: WizardSteps<boolean>;
    question: Question;
    newTopicName: string | null;
    files: FileList;
};

type QuestionWizardContextAction =
    | { type: 'nextStep' }
    | { type: 'prevStep' }
    | { type: 'setStep'; step: STEPS }
    | { type: 'setQuestion'; question: Partial<Question> }
    | { type: 'setNewTopicName'; newTopicName: string }
    | { type: 'setFiles'; files: FileList };

const disabledWizardSteps: WizardSteps<boolean> = {
    question: false,
    files: true,
    scaffolds: true,
    successCriteria: true,
    review: true,
};

/**
 * QuestionWizard helper functions to get the next or previous step based on the current step.
 */
const getNextStep = (currentStep) => {
    const currentIndex = stepsArray.indexOf(currentStep);
    return currentIndex === stepsArray.length ? null : stepsArray[currentIndex + 1];
};

const getPrevStep = (currentStep) => {
    const currentIndex = stepsArray.indexOf(currentStep);
    return currentIndex === 0 ? null : stepsArray[currentIndex - 1];
};

/**
 * QuestionWizard state and dispatch contexts & basic provider wrapper for both.
 */
const QuestionWizardContext = createContext<QuestionWizardContextState>(null);
const DispatchQuestionWizardContext =
    createContext<(action: QuestionWizardContextAction) => void>(null);

const QuestionWizardProvider = ({ children }: { children: JSX.Element }) => {
    const { questionId } = useParams();

    const { data, isLoading } = useSingleQuestion(questionId);

    const question = data?.question;

    const { attachedFiles, isLoading: isLoadingAttachments } = useQuestionFileAttachments(
        {
            question,
        },
    );

    const [state, dispatch] = useReducer(
        (
            currentState: QuestionWizardContextState,
            action: QuestionWizardContextAction,
        ) => {
            switch (action.type) {
                case 'nextStep': {
                    return { ...currentState, step: getNextStep(currentState.step) };
                }
                case 'prevStep': {
                    return { ...currentState, step: getPrevStep(currentState.step) };
                }
                case 'setStep': {
                    return { ...currentState, step: action.step };
                }
                case 'setQuestion': {
                    // This is the logic to validate if the following steps are enabled or not for
                    // the user based on their input.
                    const questionValidation =
                        action.question?.question || action.question?.question === ''
                            ? action.question.question === ''
                            : currentState.question.question === '';

                    const finishValidation = action.question?.feedback_opts
                        ? action.question?.feedback_opts?.values?.length < 1
                        : currentState.question?.feedback_opts?.values?.length < 1;

                    return {
                        ...currentState,
                        question: {
                            ...currentState.question,
                            ...action.question,
                        },
                        disabledWizardSteps: {
                            question: false,
                            files: questionValidation,
                            scaffolds: questionValidation,
                            successCriteria: questionValidation,
                            review: finishValidation || questionValidation,
                        },
                    };
                }
                case 'setNewTopicName': {
                    return {
                        ...currentState,
                        newTopicName: action.newTopicName,
                    };
                }
                case 'setFiles': {
                    return {
                        ...currentState,
                        files: action.files,
                    };
                }
                default: {
                    throw new Error(
                        // @ts-ignore
                        `Unhandled QuestionWizardContext action type: ${action.type}`,
                    );
                }
            }
        },
        {
            step: stepsArray[0],
            disabledWizardSteps,
            question: {
                id: '',
                room_id: '',
                question: '',
                is_active: false,
                feedback_opts: { values: [] },
                activity_slug: null,
                topic_id: null,
                writing_scaffolds: {},
                created_at: null,
                updated_at: null,
            },
            newTopicName: '',
            files: null,
        },
    );

    useEffect(() => {
        if (!isLoading) {
            dispatch({ type: 'setQuestion', question });
        }

        if (!isLoadingAttachments) {
            dispatch({ type: 'setFiles', files: attachedFiles });
        }
    }, [isLoading, isLoadingAttachments]);

    return (
        <QuestionWizardContext.Provider value={state}>
            <DispatchQuestionWizardContext.Provider value={dispatch}>
                {children}
            </DispatchQuestionWizardContext.Provider>
        </QuestionWizardContext.Provider>
    );
};

/**
 * QuestionWizard helper hooks to avoid using context directly and validate the use of the provider wrapper.
 */
const useQuestionWizardContext = () => {
    const context = useContext(QuestionWizardContext);
    if (context === undefined) {
        throw new Error(
            'useQuestionWizardContext must be used within a QuestionWizardProvider',
        );
    }
    return context;
};

const useDispatchQuestionWizardContext = () => {
    const context = useContext(DispatchQuestionWizardContext);
    if (context === undefined) {
        throw new Error(
            'useDispatchQuestionWizardContext must be used within a DispatchQuestionWizardContext',
        );
    }
    return context;
};

export {
    QuestionWizardProvider,
    useQuestionWizardContext,
    useDispatchQuestionWizardContext,
    type WizardSteps,
    STEPS,
    stepsArray,
    getNextStep,
    getPrevStep,
};
