import { wrapCreateBrowserRouter } from '@sentry/react';
import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import RequireAuth from '../components/atoms/RequireAuth.js';
import { ParticipantProvider } from '../components/context/Participant.js';
import { QuestionProvider } from '../components/context/Question.js';
import { QuestionWizardProvider } from '../features/QuestionWizard/context/QuestionWizard.js';
import { SessionProvider } from '../components/context/Session.js';
import AccountSettings from '../components/pages/dashboard/account/AccountSettings.js';
import { Plan } from '../components/pages/dashboard/account/Plan.js';
import Dashboard from '../components/pages/dashboard/Dashboard.js';
import QuestionWizardPage from '../features/QuestionWizard/QuestionWizardPage.js';
import Questions from '../components/pages/dashboard/questions/Questions.js';
import ForgotPassword from '../components/pages/ForgotPassword.js';
import Home from '../components/pages/Home.js';
import Join from '../components/pages/Join.js';
import NotFound from '../components/pages/NotFound.js';
import { ActivityMonitor } from '../components/pages/room/participant/ActivityMonitor.js';
import { EnterName } from '../components/pages/room/participant/EnterName.js';
import { WaitingRoom } from '../components/pages/room/participant/WaitingRoom.js';
import { WaitingRoomAvatarBuilder } from '../components/pages/room/participant/WaitingRoomAvatarBuilder.js';
import { Room } from '../components/pages/room/Room.js';
import SignIn from '../components/pages/SignIn.js';
import SignUp from '../components/pages/SignUp.js';
import VerifyEmail from '../components/pages/VerifyEmail.js';
import VerifySignUp from '../components/pages/VerifySignUp.js';
import { WaitScreen } from '../components/templates/WaitScreen.js';
import { ErrorBoundaryFallback } from '../ErrorBoundaryFallback.js';
import AddResponse from '../components/pages/room/participant/AddResponse/AddResponse.js';
import SubmissionConfirmationWaitScreen from '../components/pages/room/participant/AddResponse/SubmissionConfirmationWaitScreen.js';
import { IconLoader } from '../components/atoms/IconLoader.js';

const PairItRoutes = lazy(() => import('./PairItRoutes.js'));
const AllInRoutes = lazy(() => import('./AllInRoutes.js'));
const BattleRoyaleRoutes = lazy(() => import('./BattleRoyaleRoutes.js'));
const QuickWriteRoutes = lazy(() => import('./QuickWriteRoutes.js'));

const Results = lazy(() => import('../features/Results/ResultsPage.js'));
const SessionReport = lazy(
    () => import('../features/SessionReport/SessionReportPage.js'),
);
const Discover = lazy(() => import('../components/pages/dashboard/discover/Discover.js'));
const Account = lazy(() => import('../components/pages/dashboard/account/Account.js'));

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
        <Route errorElement={<ErrorBoundaryFallback />}>
            <Route path="*" element={<NotFound />} />
            <Route index element={<Home />} />
            <Route path="sign-up" element={<SignUp />} />
            <Route path="sign-in" element={<SignIn />} />
            <Route path="verify-sign-up" element={<VerifySignUp />} />
            <Route path="verify-email" element={<VerifyEmail />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="join" element={<Join />} />

            {/* RequireAuth will redirect all nested routes to /sign-in if the user is not
            authenticated */}
            <Route element={<RequireAuth />}>
                <Route path="dashboard" element={<Dashboard />}>
                    <Route path="questions">
                        <Route index element={<Questions />} />
                    </Route>
                    <Route path="results">
                        <Route
                            index
                            element={
                                <Suspense fallback={<IconLoader />}>
                                    <Results />
                                </Suspense>
                            }
                        />
                        <Route
                            path=":sessionId/:questionId/:activitySlug"
                            element={
                                <Suspense fallback={<IconLoader />}>
                                    <SessionReport />
                                </Suspense>
                            }
                        />
                    </Route>
                    <Route path="discover">
                        <Route
                            index
                            element={
                                <Suspense fallback={<IconLoader />}>
                                    <Discover />
                                </Suspense>
                            }
                        />
                    </Route>
                    <Route
                        path="account"
                        element={
                            <Suspense fallback={<IconLoader />}>
                                <Account />
                            </Suspense>
                        }
                    >
                        <Route path="settings" element={<AccountSettings />} />
                        <Route path="plan" element={<Plan />} />
                    </Route>
                </Route>
                <Route
                    // This question mark makes the questionId param optional
                    path="questions/wizard/:questionId?"
                    element={
                        <QuestionWizardProvider>
                            <QuestionWizardPage />
                        </QuestionWizardProvider>
                    }
                />
            </Route>
            <Route
                path="room/:joinCode"
                element={
                    <QuestionProvider>
                        <SessionProvider>
                            <Room />
                        </SessionProvider>
                    </QuestionProvider>
                }
            >
                {/* PAIR IT ROUTES */}
                <Route
                    path="pair-it/*"
                    element={
                        <Suspense
                            fallback={
                                <WaitScreen message="Loading..." loaderColor="yellow" />
                            }
                        >
                            <PairItRoutes />
                        </Suspense>
                    }
                />

                {/* ALL IN ROUTES */}
                <Route
                    path="all-in/*"
                    element={
                        <Suspense
                            fallback={
                                <WaitScreen message="Loading..." loaderColor="yellow" />
                            }
                        >
                            <AllInRoutes />
                        </Suspense>
                    }
                />

                {/* BATTLE ROYALE ROUTES */}
                <Route
                    path="battle-royale/*"
                    element={
                        <Suspense
                            fallback={
                                <WaitScreen message="Loading..." loaderColor="yellow" />
                            }
                        >
                            <BattleRoyaleRoutes />
                        </Suspense>
                    }
                />

                {/* QUICK WRITE ROUTES */}
                <Route
                    path="quick-write/*"
                    element={
                        <Suspense
                            fallback={
                                <WaitScreen message="Loading..." loaderColor="yellow" />
                            }
                        >
                            <QuickWriteRoutes />
                        </Suspense>
                    }
                />

                {/* GENERIC ROUTES - students navigate to these routes when entering a room for the first time or if a question hasn't been launched yet */}
                <Route
                    path="participant"
                    element={
                        <ParticipantProvider>
                            <ActivityMonitor />
                        </ParticipantProvider>
                    }
                >
                    <Route path="enter-name" element={<EnterName />} />
                    <Route path="add-response">
                        <Route index element={<AddResponse />} />
                        <Route
                            path="submitted"
                            element={<SubmissionConfirmationWaitScreen />}
                        />
                    </Route>
                    <Route path="waiting-room" element={<WaitingRoom />} />
                    <Route path="avatar-builder" element={<WaitingRoomAvatarBuilder />} />
                </Route>
            </Route>
        </Route>,
    ),
);

export default router;
