import React from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import styles from './_styles/QuestionWizardPage.scss';
import ExitLeft from '../../components/icons/ExitLeft.js';
import QuestionWizardTabs from './components/QuestionWizardTabs.js';
import Review from './components/QuestionWizardSteps/Review.js';
import Files from './components/QuestionWizardSteps/Files.js';
import QuestionPrompt from './components/QuestionWizardSteps/QuestionPrompt.js';
import Scaffolds from './components/QuestionWizardSteps/Scaffolds.js';
import SuccessCriteria from './components/QuestionWizardSteps/SuccessCriteria.js';
import {
    useQuestionWizardContext,
    WizardSteps,
    useDispatchQuestionWizardContext,
} from './context/QuestionWizard.js';

const wizardComponents: WizardSteps<React.JSX.Element> = {
    question: <QuestionPrompt />,
    files: <Files />,
    scaffolds: <Scaffolds />,
    successCriteria: <SuccessCriteria />,
    review: <Review />,
};

const QuestionWizardPage = () => {
    const navigate = useNavigate();

    const questionWizardState = useQuestionWizardContext();
    const questionWizardDispatch = useDispatchQuestionWizardContext();

    const handleBack = () => {
        questionWizardDispatch({
            type: 'setQuestion',
            question: {
                id: '',
                room_id: '',
                question: '',
                is_active: false,
                feedback_opts: { values: [] },
                activity_slug: null,
                topic_id: null,
                writing_scaffolds: {},
                created_at: null,
                updated_at: null,
            },
        });

        navigate('/dashboard/questions');
    };

    return (
        <div className={styles.columns}>
            <div className={styles.left_column}>
                <IconButton
                    className={styles.exit_icon_button}
                    onClick={handleBack}
                    aria-label="back"
                >
                    <ExitLeft className={styles.exit_icon} />
                </IconButton>

                <QuestionWizardTabs />
            </div>

            <div className={styles.right_column}>
                {wizardComponents[questionWizardState.step]}
            </div>
        </div>
    );
};

export default QuestionWizardPage;
