export const getDataFromSessionStorage = (sessionStorageKey: string): any => {
    const dataString = sessionStorage?.getItem(sessionStorageKey);

    if (dataString) {
        return JSON?.parse(dataString);
    }
};

export const cacheDataInSessionStorage = (
    sessionStorageKey: string,
    data: Object,
): null => {
    if (data) {
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(data));
    }

    return null;
};
