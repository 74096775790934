import { AnalyticsBrowser } from '@segment/analytics-next';

let writeKey = '';

const url = window.location.href;

if (url.includes('localhost') || url.includes('test')) {
    /** Short Answer Test Source in Segment */
    writeKey = 'Sc7dJ5M70OvG7WlrWCCh9mJwoxc0paDS';
} else {
    /** Short Answer Beta Web Source in Segment */
    writeKey = 'TUatDRmcURUGRm43iGs2p0epmHuWTKAW';
}

export const analytics = AnalyticsBrowser.load({
    writeKey,
});

export const trackEvent = (event: string, data: any = {}) => {
    return analytics.track(event, data);
};
