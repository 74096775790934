import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { captureException } from '@sentry/react';
import cn from 'classnames';
import styles from '../_styles/QuestionWizardSteps/Review.scss';
import { WritingScaffolds } from '../../../../../types/WritingScaffolds.js';
import { useQuestionWizardContext } from '../../context/QuestionWizard.js';
import { useAllRoomTopics } from '../../../../components/hooks/queries/useAllRoomTopics.js';
import { useAddQuestion } from '../../../../components/hooks/mutations/useAddQuestion.js';
import { useAddTopic } from '../../../../components/hooks/mutations/useAddTopic.js';
import { convertScaffoldKeyToPlainText } from '../../../../components/_helpers/convertScaffoldKeyToPlainText.js';
import { AnalyticsEvents } from '../../../../../types/AnalyticsEvents.js';
import { trackEvent } from '../../../../lib/analytics.js';
import { QueryKeys } from '../../../../../constants/queryKeys.js';
import QuestionWizardStepLayout from '../QuestionWizardStepLayout.js';
import SuccessCriteriaCard from '../SuccessCriteriaCard.js';
import FileAttachmentThumbnail from '../../../../components/molecules/FileAttachmentThumbnailV2.js';
import { PreviewFileModal } from '../../../../components/molecules/modals/PreviewFileModal.js';
import { CreatingQuestionSplashScreen } from '../CreatingQuestionSplashScreen.js';
import { useEditQuestion } from '../../../../components/hooks/mutations/useEditQuestion.js';
import { ArcadePreview } from '../../../../components/organisms/ArcadePreview.js';

const Review = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const location = useLocation();

    const isDuplicate = location.state?.isDuplicate ?? false;

    const addQuestion = useAddQuestion();
    const editQuestion = useEditQuestion();
    const addTopic = useAddTopic();

    const questionWizardState = useQuestionWizardContext();

    const [showFilePreview, setShowFilePreview] = useState<File | null>(null);
    const [showCreatingQuestionSplashScreen, setShowCreatingQuestionSplashScreen] =
        useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState<string | null>(null);

    // Destructuring state for several process
    const {
        question: {
            id,
            question,
            feedback_opts: feedbackOpts,
            writing_scaffolds: writingScaffolds,
            topic_id: topicId,
        },
        newTopicName,
        files,
    } = questionWizardState;

    const fileListKeys = files && Object.keys(files);
    const scaffolds = Object.keys(writingScaffolds ?? {});

    const { data: topicsData } = useAllRoomTopics();
    const topics = topicsData?.topics ?? [];

    const topic = topics.find((t) => t.id === topicId) ?? {
        id: null,
        name: newTopicName || 'No Topic',
    };

    const handleAddTopic = async () => {
        try {
            if (newTopicName && newTopicName !== '') {
                const res = await addTopic.mutateAsync({
                    name: newTopicName,
                });

                return res.topic;
            }
        } catch (e) {
            captureException(e);
        }

        return null;
    };

    const handleOnSuccess = () => {
        if (isSuccess)
            navigate('/dashboard/questions', {
                state: { alertMessage: 'Question successfully created!' },
            });
    };

    const handleOnSubmit = async () => {
        setShowCreatingQuestionSplashScreen(true);

        trackEvent(AnalyticsEvents.questionSaved, {
            location: 'add_edit_question_form',
            question,
            question_id: id,
            feedback_criteria: feedbackOpts,
            feedback_count: feedbackOpts?.values.length,
        });

        const newTopic = await handleAddTopic();

        const update = {
            question: {
                ...questionWizardState.question,
                // If the topic is new, use the new topic id, otherwise use the existing topic id from the question conext
                topic_id: newTopic?.id ?? topicId,
            },
            filesToUpload: files,
        };

        const onSuccess = () => {
            queryClient.invalidateQueries(QueryKeys.allRoomQuestions);
            setIsSuccess(true);
            setShowCreatingQuestionSplashScreen(false);
        };

        const onError = (e: Error) => {
            setIsSuccess(false);
            if (e.message === 'Request failed with status code 429') {
                setError(
                    'You have reached the maximum number of questions for your plan. Upgrade to premium to add more questions.',
                );
            } else {
                setError('Error adding question. Please try again.');
            }
            captureException(error);
            setShowCreatingQuestionSplashScreen(false);
        };

        // If the question has an id, it means it already exists so we should edit it
        if (id && !isDuplicate) {
            await editQuestion.mutateAsync(update, {
                onSuccess,
                onError,
            });
        } else {
            await addQuestion.mutateAsync(update, {
                onSuccess,
                onError,
            });
        }
    };

    return (
        <QuestionWizardStepLayout
            title={
                <>
                    <strong>Review your question or prompt</strong> to make sure
                    everything looks right.
                </>
            }
            infoBarTitle="Preview how this will work with your class"
            infoBarContents={
                <>
                    <h2>Preview how this will work with your class</h2>
                    <p>
                        The walkthrough below will demo how this question or prompt would
                        work with activities in Short Answer.
                    </p>
                    <ArcadePreview />
                </>
            }
            defaultExpanded
            primaryCTALabel={id ? 'Save & Exit' : 'Create & Exit'}
            onSubmit={handleOnSubmit}
        >
            <div className={styles.section}>
                <h5>Question/Prompt:</h5>
                <h6>{questionWizardState.question.question}</h6>
            </div>

            <div className={styles.section}>
                <h5>Files:</h5>
                <div className={styles.file_attachment_wrapper}>
                    {!fileListKeys && <p>No files attached</p>}
                    {fileListKeys?.map((fileKey) => {
                        const file = files[fileKey];

                        return (
                            <FileAttachmentThumbnail
                                key={fileKey}
                                className={styles.file_attachment}
                                file={file}
                                onClick={setShowFilePreview}
                            />
                        );
                    })}
                </div>
            </div>

            <div className={cn([styles.section, styles.inline])}>
                <h5>Topic:</h5>
                <p>{topic.name}</p>
            </div>

            <div className={cn([styles.section, styles.inline])}>
                <h5>Scaffolds:</h5>
                <p>
                    {scaffolds
                        .map((scaffold) =>
                            convertScaffoldKeyToPlainText(
                                scaffold as keyof WritingScaffolds,
                            ),
                        )
                        .join('; ')}
                </p>
            </div>

            <div className={styles.section}>
                <h5>
                    Success Criteria (
                    {questionWizardState.question.feedback_opts?.values?.length}):
                </h5>
                <div className={styles.criteria_list_container}>
                    {questionWizardState.question.feedback_opts?.values?.map((c) => (
                        <SuccessCriteriaCard key={c.id} feedbackOpt={c} />
                    ))}
                </div>
            </div>

            <PreviewFileModal
                fileList={files ?? {}}
                initialSelectedFile={showFilePreview}
                open={!!showFilePreview}
                onClose={() => setShowFilePreview(null)}
            />

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={!!error}
                autoHideDuration={7000}
            >
                <Alert severity="error" onClose={() => setError(null)}>
                    {error}
                </Alert>
            </Snackbar>

            <CreatingQuestionSplashScreen
                action={id ? 'Saving' : 'Creating'}
                open={showCreatingQuestionSplashScreen}
                onFinishAnimation={handleOnSuccess}
            />
        </QuestionWizardStepLayout>
    );
};

export default Review;
