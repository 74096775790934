import React, { useState, useEffect } from 'react';
import FormHelperText from '@mui/material/FormHelperText';

import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import styles from '../_styles/QuestionWizardSteps/QuestionPrompt.scss';
import Close from '../../../../components/icons/Close.js';
import TextInput from '../../../../components/atoms/TextInput.js';
import TextAreaInput from '../../../../components/atoms/TextAreaInput.js';
import {
    useQuestionWizardContext,
    useDispatchQuestionWizardContext,
} from '../../context/QuestionWizard.js';
import { assetsUrlPrefix } from '../../../../../constants/assetsUrlPrefix.js';
import QuestionWizardStepLayout from '../QuestionWizardStepLayout.js';
import { useAllRoomTopics } from '../../../../components/hooks/queries/useAllRoomTopics.js';
import QuestionMarkTooltip from '../../../../components/atoms/QuestionMarkTooltip.js';
import { PreviewImageModal } from '../../../../components/molecules/modals/PreviewImageModal.js';

const imgFileName = 'question_prompt_table_';

const QuestionPrompt = () => {
    const questionWizardState = useQuestionWizardContext();
    const questionWizardDispatch = useDispatchQuestionWizardContext();

    const [textAreaInputError, setTextAreaInputError] = useState('');
    const [showTopicInput, setShowTopicInput] = useState(
        !!questionWizardState.newTopicName,
    );
    const [showDuplicateTopicWarning, setShowDuplicateTopicWarning] = useState(false);
    const [showImagePreview, setShowImagePreview] = useState(false);

    const { data: topicsData } = useAllRoomTopics();
    const { topics } = topicsData ?? { topics: [] };

    useEffect(() => {
        if (topics?.find((topic) => topic?.name === questionWizardState.newTopicName)) {
            setShowDuplicateTopicWarning(true);
        } else if (showDuplicateTopicWarning) {
            setShowDuplicateTopicWarning(false);
        }
    }, [questionWizardState.newTopicName]);

    const imgSrc = `${assetsUrlPrefix}/images/${imgFileName}2x.png`;
    const imgSrcSet = `${assetsUrlPrefix}/images/${imgFileName}1x.png 1x, ${assetsUrlPrefix}/images/${imgFileName}2x.png 2x, ${assetsUrlPrefix}/images/${imgFileName}3x.png 3x`;
    const imgAlt = 'Great question or prompt suggestions table';

    return (
        <>
            <QuestionWizardStepLayout
                title={
                    <>
                        What <strong>question</strong> or <strong>writing prompt</strong>{' '}
                        do you want to ask your class?
                    </>
                }
                infoBarTitle="How do I write a good question or prompt?"
                infoBarContents={
                    <>
                        <h2>To write a great question or prompt...</h2>
                        <ul>
                            <li>
                                Use the stems from the Purple, Yellow, or Green box below
                                (you can click to expand)
                            </li>
                            <li>
                                If you want to use fact-based recall stems like those in
                                the Pink box, pair them with questions or prompts from the
                                Purple, Yellow, or Green boxes.
                            </li>
                            <li>
                                For example, instead of asking “Who was the first
                                president of the USA?” ask “Who was the first president of
                                the USA and how did he influence the development of the
                                presidency?”
                            </li>
                        </ul>
                        <img
                            src={imgSrc}
                            srcSet={imgSrcSet}
                            alt={imgAlt}
                            onClick={() => setShowImagePreview(true)}
                            className={styles.img}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    setShowImagePreview(true);
                                }
                            }}
                            tabIndex={0}
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                            role="button"
                        />
                    </>
                }
            >
                <TextAreaInput
                    className={styles.input}
                    autoFocus
                    id="question_prompt"
                    onChange={(text) =>
                        questionWizardDispatch({
                            type: 'setQuestion',
                            question: { question: text },
                        })
                    }
                    onError={setTextAreaInputError}
                    defaultValue={questionWizardState.question?.question}
                    showMathToolbarIcon
                    showAttachmentIcon={false}
                />

                {textAreaInputError && (
                    <FormHelperText className={styles.error} error>
                        {textAreaInputError}
                    </FormHelperText>
                )}

                <div className={styles.topic_field}>
                    <InputLabel className={styles.topic_label} htmlFor="topic">
                        <span>
                            <strong>Add Topic</strong> (optional){' '}
                        </span>
                        <QuestionMarkTooltip
                            tooltipClassName={styles.tooltip}
                            tooltipTitle="Topics help organize the questions in your account"
                        />
                    </InputLabel>
                    {showTopicInput ? (
                        <>
                            <TextInput
                                id="topic"
                                autoFocus={showTopicInput}
                                value={questionWizardState.newTopicName}
                                endAdornment={
                                    <IconButton
                                        onClick={() => {
                                            setShowTopicInput(false);
                                            questionWizardDispatch({
                                                type: 'setNewTopicName',
                                                newTopicName: '',
                                            });
                                            questionWizardDispatch({
                                                type: 'setQuestion',
                                                question: { topic_id: null },
                                            });
                                        }}
                                    >
                                        <Close className={styles.close} />
                                    </IconButton>
                                }
                                inputProps={{
                                    maxLength: 80,
                                }}
                                onChange={(e) =>
                                    questionWizardDispatch({
                                        type: 'setNewTopicName',
                                        newTopicName: e.target.value,
                                    })
                                }
                                className={styles.topic_input}
                                variant="outlined"
                            />
                            {showDuplicateTopicWarning && (
                                <FormHelperText className={styles.error} error>
                                    <strong>{questionWizardState.newTopicName}</strong>{' '}
                                    already exists
                                </FormHelperText>
                            )}
                        </>
                    ) : (
                        <Select
                            id="topic"
                            value={questionWizardState.question?.topic_id ?? 'no-topic'}
                            onChange={(e) => {
                                if (
                                    e.target.value === 'create-topic' ||
                                    e.target.value === 'no-topic'
                                ) {
                                    questionWizardDispatch({
                                        type: 'setQuestion',
                                        question: { topic_id: null },
                                    });
                                    setShowTopicInput(true);
                                    return;
                                }

                                questionWizardDispatch({
                                    type: 'setQuestion',
                                    question: { topic_id: e.target.value },
                                });
                            }}
                            MenuProps={{
                                className: styles.topic_input,
                            }}
                            className={styles.topic_input}
                        >
                            <MenuItem value="no-topic">No Topic</MenuItem>
                            <MenuItem value="create-topic">Create Topic</MenuItem>
                            {topics?.length > 0 && <Divider />}
                            {topics?.map((t) => {
                                return (
                                    <MenuItem key={t.id} value={t.id}>
                                        <Typography variant="inherit" noWrap>
                                            {t.name}
                                        </Typography>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    )}
                </div>
            </QuestionWizardStepLayout>
            <PreviewImageModal
                src={imgSrc}
                alt={imgAlt}
                open={showImagePreview}
                onClose={() => setShowImagePreview(false)}
            />
        </>
    );
};

export default QuestionPrompt;
